import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { jobs_helper } from "@/helpers/jobs_helper";
import { speciality_helper } from "@/helpers/speciality_helper";
import slug from "slug";
import { ReciteMeWidget } from "@/ui";
import sn from "@/snippets";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
      <ReciteMeWidget />
      <script type="text/javascript" async src="https://bot.leadoo.com/bot/chat.js?code=af9d061d" />
    </>
  );
}

export async function getStaticProps({}) {
  const specialities = speciality_helper.fetch();

  return {
    props: {
      meta: { title: createTitle("Home") },
      content: [
        {
          component: "Hero",
          props: {
            title: {
              path: "page.home.component.Hero.title",
              placeholder: "Workforce management solutions to the international healthcare market",
            },
            img: { path: "page.home.component.Hero.img" },
          },
        },
        { component: "LeadooWidget" },
        { component: "Divider" },
        sn.gridButtons({
          className: "bg-white py-4 py-md-5 text-center",
          title: { path: "page.home.component.GridButtonsGrid.title", placeholder: "Our specialist sectors" },
          description: {
            path: "page.home.component.GridButtonsGrid.description",
            placeholder:
              "As a doctor, whether you wish to work in Australia, Ireland, New Zealand or the UK, our passionate people are here to help you find a locum, contract or permanent job that is right for you.",
          },
          items: specialities
            .filter((i) => !!i["icon_image"])
            .map((i) => ({
              title: i["title"] ?? null,
              img: i["icon_image"] ?? null,
              href: `/jobs/#/${slug(i.title)}`,
            })),
        }),
        { component: "Divider" },
        {
          component: "LatestJobs",
          props: {
            title: { path: "page.home.component.LatestJobs.title", placeholder: "Latest Jobs" },
            items: jobs_helper.fetch(),
            filters: true,
          },
        },
      ],
    },
  };
}
